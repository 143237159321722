import React, { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useService } from '../hooks/useService';
import SessionStorage from '../../services/SessionStorage';

import { BASE_GROUP_LINKS } from '../config';
import { KEY_USER, STORAGE } from '../constants/storage';
import ImageSpinner from './Spinner/ImageSpinner';

export default function PrivateRoute({ component: Component, ...rest }) {
    const session = useService(SessionStorage);

    const { accessToken } = session.getSession();
    const { admin } = session.get(KEY_USER, STORAGE.LOCAL);

    return accessToken || admin ? (
        <Suspense fallback={<ImageSpinner />}>
            <Outlet />
        </Suspense>
    ) : (
        <Navigate to={BASE_GROUP_LINKS.LINK_404} />
    );
}

PrivateRoute.propTypes = {
    component: PropTypes.object
};
