import React from 'react';

import loadingImage from '../../assets/logo.svg';

const ImageSpinner = () => {
    return (
        <div className="preload d-flex align-items-center justify-content-center">
            <img className="image-spinner" alt="" src={loadingImage} width={70} />
        </div>
    );
};

export default ImageSpinner;
