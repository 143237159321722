import { lazy } from 'react';
import { APP_GROUP_LINKS } from '../links';
import { Navigate } from 'react-router-dom';

// components
const Login = lazy(() => import('../../groups/admins/auth/pages/Login/components/LoginContainer'));
const Logout = lazy(() => import('../../groups/admins/auth/pages/Logout'));
const Page404 = lazy(() => import('../pages/404'));

const publicRoutes = [
    {
        key: 'login',
        path: APP_GROUP_LINKS.ADMIN.AUTH_GROUP_LINKS.LINK_TO_LOGIN,
        element: <Login />,
        authority: []
    },
    {
        key: 'logout',
        path: APP_GROUP_LINKS.ADMIN.AUTH_GROUP_LINKS.LINK_TO_LOGOUT,
        element: <Logout />,
        authority: []
    },
    {
        key: 'not-found',
        path: APP_GROUP_LINKS.LINK_404,
        element: <Page404 />,
        authority: []
    },
    {
        key: 'default',
        path: '*',
        element: <Navigate to={APP_GROUP_LINKS.LINK_404} />,
        authority: []
    }
];

export default publicRoutes;
