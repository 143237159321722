import { lazy } from 'react';
import { APP_GROUP_LINKS } from '../links';
import { Navigate } from 'react-router-dom';

// components
const TemplateDetails = lazy(() => import('../../groups/admins/emails/pages/EmailDetails'));
const PagesList = lazy(() => import('../../groups/admins/pages/pages/PagesList'));
const PageDetails = lazy(() => import('../../groups/admins/pages/pages/PageDetails'));
const ReportDetails = lazy(() => import('../../groups/admins/reports/pages/ReportDetails'));
const ReportsList = lazy(() => import('../../groups/admins/reports/pages/ReportsList'));
const UsersList = lazy(() => import('../../groups/admins/users/pages/UsersList'));
const UserInfo = lazy(() => import('../../groups/admins/users/pages/UserProfile'));
const UserDetails = lazy(() => import('../../groups/admins/users/pages/UserDetails'));

const emailsRoutes = [
    {
        key: 'template-details',
        path: APP_GROUP_LINKS.ADMIN.EMAILS_GROUP_LINKS.LINK_TO_TEMPLATE_DETAILS,
        element: <TemplateDetails />,
        authority: []
    },
    {
        key: 'default',
        path: '*',
        element: <Navigate to={APP_GROUP_LINKS.ADMIN.EMAILS_GROUP_LINKS.LINK_TO_EMAILS} />,
        authority: []
    }
];

const pagesRoutes = [
    {
        key: 'pages-list',
        path: APP_GROUP_LINKS.ADMIN.PAGES_GROUP_LINKS.LINK_TO_PAGES,
        element: <PagesList />,
        authority: []
    },
    {
        key: 'private-policy',
        path: APP_GROUP_LINKS.ADMIN.PAGES_GROUP_LINKS.PRIVATE_POLICY_GROUPS_LINKS.LINK_TO_PRIVATE_POLICY_DETAILS,
        element: <PageDetails />,
        authority: []
    },
    {
        key: 'page-details',
        path: APP_GROUP_LINKS.ADMIN.PAGES_GROUP_LINKS.LINK_TO_PAGE_DETAILS,
        element: <PageDetails />,
        authority: []
    },
    {
        key: 'default',
        path: '*',
        element: <Navigate to={APP_GROUP_LINKS.ADMIN.PAGES_GROUP_LINKS.LINK_TO_PAGES} />,
        authority: []
    }
];

const reportsRoutes = [
    {
        key: 'reports-list',
        path: APP_GROUP_LINKS.ADMIN.REPORT_GROUP_LINKS.LINK_TO_REPORTS,
        element: <ReportsList />,
        authority: []
    },
    {
        key: 'report-details',
        path: APP_GROUP_LINKS.ADMIN.REPORT_GROUP_LINKS.LINK_TO_REPORT_DETAILS,
        element: <ReportDetails />,
        authority: []
    }
];

const usersRoutes = [
    {
        key: 'users-list',
        path: APP_GROUP_LINKS.ADMIN.USERS_GROUP_LINKS.LINK_TO_USERS,
        element: <UsersList />,
        authority: []
    },
    {
        key: 'user-info',
        path: APP_GROUP_LINKS.ADMIN.USERS_GROUP_LINKS.LINK_TO_USER_PROFILE,
        element: <UserInfo />,
        authority: []
    },
    {
        key: 'user-details',
        path: APP_GROUP_LINKS.ADMIN.USERS_GROUP_LINKS.LINK_TO_USER_DETAILS,
        element: <UserDetails />,
        authority: []
    }
];

export { emailsRoutes, pagesRoutes, reportsRoutes, usersRoutes };
