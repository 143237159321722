import React from 'react';
import ReactDOM from 'react-dom/client';

// components
import App from './App';
import Context from './Context';

const root = ReactDOM.createRoot(document.getElementById('root'));
const Application = (
    <Context>
        <App />
    </Context>
);

root.render(Application);
