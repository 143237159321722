import { lazy } from 'react';
import { APP_GROUP_LINKS } from '../links';

// components
const VerifyAccount = lazy(() => import('../../groups/accounts/pages/Verification'));
const ResetPassword = lazy(() => import('../../groups/accounts/pages/ResetPassword'));

const accountRoutes = [
    {
        key: 'verify',
        path: APP_GROUP_LINKS.ACCOUNT.LINK_TO_VERIFICATION_PAGE,
        element: <VerifyAccount />,
        authority: []
    },
    {
        key: 'reset-password',
        path: APP_GROUP_LINKS.ACCOUNT.LINK_TO_PASSWORD_PAGE,
        element: <ResetPassword />,
        authority: []
    }
];

export default accountRoutes;
