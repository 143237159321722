import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

// components
import TranslateContainer from './base/contexts/TranslateContainer';
import ErrorBoundary from './base/components/ErrorBoundary';

// locales
import en from './i18n/en';
import ru from './i18n/ru';

// redux storage
import store from './store';

// constants
import { LANGUAGES } from './base/constants/languages';

/**
 * @enum
 */
const languages = {
    [LANGUAGES.EN]: en,
    [LANGUAGES.RU]: ru
};

const Context = ({ children }) => {
    return (
        <Provider store={store}>
            <TranslateContainer languages={languages} initialLanguage={LANGUAGES.EN}>
                <BrowserRouter>
                    <ErrorBoundary>{children}</ErrorBoundary>
                </BrowserRouter>
            </TranslateContainer>
        </Provider>
    );
};

Context.propTypes = {
    children: PropTypes.any
};

export default Context;
