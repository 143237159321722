import { BASE_GROUP_LINKS } from './config';
import { APP_VERSION } from './constants/appVersion';

const PAGES_GROUP_LINKS_BASE = `/${APP_VERSION.concat(BASE_GROUP_LINKS.LINK_ADMIN)}/pages`;
const EMAILS_GROUP_LINKS_BASE = `/${APP_VERSION.concat(BASE_GROUP_LINKS.LINK_ADMIN)}/emails`;
const SUPPORT_TICKETS_GROUP_LINKS_BASE = `/${APP_VERSION.concat(BASE_GROUP_LINKS.LINK_ADMIN)}/support-tickets`;
const REPORTS_GROUP_LINKS_BASE = `/${APP_VERSION.concat(BASE_GROUP_LINKS.LINK_ADMIN)}/reports`;
const USERS_GROUP_LINKS_BASE = `/${APP_VERSION.concat(BASE_GROUP_LINKS.LINK_ADMIN)}/users`;

export const APP_GROUP_LINKS = {
    CLIENT: {
        BASE: BASE_GROUP_LINKS.LINK_CLIENT,
        LINK_TO_PAGE_DETAILS: '/:pageName',
        LINK_TO_HOME: BASE_GROUP_LINKS.LINK_CLIENT,
        LINK_TO_ABOUT_US: '/about-us',
        LINK_TO_CONTACT_US: '/contact-us',
        LINK_TO_PRIVACY_POLICY_GROUP: {
            BASE: '/privacy',
            LINK_TO_PRIVATE_POLICY_DETAILS: '/privacy/:pageName',
            LINK_TO_PRIVATE_POLICY: '/privacy/privacy-policy',
            LINK_TO_TERMS_AND_CONDITIONS: '/privacy/terms-and-conditions'
        }
    },
    ACCOUNT: {
        BASE: BASE_GROUP_LINKS.LINK_ACCOUNT,
        LINK_TO_VERIFICATION_PAGE: `${BASE_GROUP_LINKS.LINK_ACCOUNT}/verification`,
        LINK_TO_PASSWORD_PAGE: `${BASE_GROUP_LINKS.LINK_ACCOUNT}/password`
    },
    ADMIN: {
        BASE: `/${APP_VERSION.concat(BASE_GROUP_LINKS.LINK_ADMIN)}`,
        AUTH_GROUP_LINKS: {
            LINK_TO_LOGIN: `${BASE_GROUP_LINKS.LINK_ADMIN}/login`,
            LINK_TO_LOGOUT: `${BASE_GROUP_LINKS.LINK_ADMIN}/logout`
        },
        PAGES_GROUP_LINKS: {
            BASE: PAGES_GROUP_LINKS_BASE,
            LINK_TO_PAGES: PAGES_GROUP_LINKS_BASE,
            LINK_TO_HOME_PAGE: `${PAGES_GROUP_LINKS_BASE}/home`,
            LINK_TO_ABOUTS_US_PAGE: `${PAGES_GROUP_LINKS_BASE}/about-us`,
            LINK_TO_CONTACT_US_PAGE: `${PAGES_GROUP_LINKS_BASE}/contact-us`,
            LINK_TO_VERIFICATION_PAGE: `${PAGES_GROUP_LINKS_BASE}/verification`,
            LINK_TO_RESET_PASSWORD_PAGE: `${PAGES_GROUP_LINKS_BASE}/password`,
            LINK_TO_PAGE_DETAILS: `${PAGES_GROUP_LINKS_BASE}/:pageName`,
            PRIVATE_POLICY_GROUPS_LINKS: {
                BASE: `${BASE_GROUP_LINKS.LINK_ADMIN}/privacy`,
                LINK_TO_PRIVATE_POLICY_DETAILS: `${PAGES_GROUP_LINKS_BASE}/privacy/:pageName`,
                LINK_TO_PRIVATE_POLICY: `${PAGES_GROUP_LINKS_BASE}/privacy/privacy-policy`,
                LINK_TO_TERMS_AND_CONDITIONS: `${PAGES_GROUP_LINKS_BASE}/privacy/terms-and-conditions`
            }
        },
        EMAILS_GROUP_LINKS: {
            BASE: EMAILS_GROUP_LINKS_BASE,
            LINK_TO_EMAILS: EMAILS_GROUP_LINKS_BASE,
            LINK_TO_VERIFICATION_TEMPLATE: `${EMAILS_GROUP_LINKS_BASE}/verification`,
            LINK_TO_WELCOME_TEMPLATE: `${EMAILS_GROUP_LINKS_BASE}/welcome`,
            LINK_TO_ACCOUNT_REGISTRATION_TEMPLATE: `${EMAILS_GROUP_LINKS_BASE}/new-account`,
            LINK_TO_FORGOT_PASSWORD_TEMPLATE: `${EMAILS_GROUP_LINKS_BASE}/password`,
            LINK_TO_SUPPORT_TEMPLATE: `${EMAILS_GROUP_LINKS_BASE}/support`,
            LINK_TO_TEMPLATE_DETAILS: `${EMAILS_GROUP_LINKS_BASE}/:templateName`
        },
        SUPPORT_GROUP_LINKS: {
            BASE: SUPPORT_TICKETS_GROUP_LINKS_BASE,
            LINK_TO_SUPPORT_TICKETS: SUPPORT_TICKETS_GROUP_LINKS_BASE,
            LINK_TO_SUPPORT_TICKET_DETAILS: `${SUPPORT_TICKETS_GROUP_LINKS_BASE}/:id`
        },
        REPORT_GROUP_LINKS: {
            BASE: REPORTS_GROUP_LINKS_BASE,
            LINK_TO_REPORTS: REPORTS_GROUP_LINKS_BASE,
            LINK_TO_REPORT_DETAILS: `${REPORTS_GROUP_LINKS_BASE}/:id`
        },
        USERS_GROUP_LINKS: {
            BASE: USERS_GROUP_LINKS_BASE,
            LINK_TO_USERS: USERS_GROUP_LINKS_BASE,
            LINK_TO_USER_PROFILE: `${USERS_GROUP_LINKS_BASE}/:id`,
            LINK_TO_USER_DETAILS: `${USERS_GROUP_LINKS_BASE}/:id/details`
        }
    },
    LINK_404: BASE_GROUP_LINKS.LINK_404
};
