import React, { Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import publicRoutes from './base/routes/publicRoutes';

// components
import ImageSpinner from './base/components/Spinner/ImageSpinner';
import PrivateRoute from './base/components/PrivateRoute';

// constants
import { APP_GROUP_LINKS } from './base/links';

// styles
import './assets/scss/common-styles.scss';

// routes
import { emailsRoutes, pagesRoutes, reportsRoutes, usersRoutes } from './base/routes/adminRoutes';
import clientRoutes from './base/routes/clientRoutes';
import accountRoutes from './base/routes/accountRoutes';

// admin components
const Pages = lazy(() => import('./groups/admins/pages'));
const Emails = lazy(() => import('./groups/admins/emails'));
const Support = lazy(() => import('./groups/admins/supports'));
const Report = lazy(() => import('./groups/admins/reports'));
const Users = lazy(() => import('./groups/admins/users'));

// components
const AccountRoutes = lazy(() => import('./groups/accounts'));
const AdminsAppRoutes = lazy(() => import('./groups/admins'));
const ClientAppRoutes = lazy(() => import('./groups/clients'));

const App = () => {
    const getRoutes = routes =>
        routes.map(route => <Route key={route.key} path={route.path} element={route.element} />);

    return (
        <Suspense fallback={<ImageSpinner />}>
            <Routes>
                {publicRoutes.map(route => (
                    <Route key={route.key} path={route.path} element={route.element} />
                ))}

                {/* Admin Content Management System */}
                <Route path={`${APP_GROUP_LINKS.ADMIN.BASE}`} element={<PrivateRoute />}>
                    <Route path={`${APP_GROUP_LINKS.ADMIN.BASE}`} element={<AdminsAppRoutes />}>
                        {/* Route to pages */}
                        <Route path={APP_GROUP_LINKS.ADMIN.PAGES_GROUP_LINKS.LINK_TO_PAGES} element={<Pages />}>
                            {getRoutes(pagesRoutes)}
                        </Route>

                        {/* Route to email templates */}
                        <Route path={APP_GROUP_LINKS.ADMIN.EMAILS_GROUP_LINKS.LINK_TO_EMAILS} element={<Emails />}>
                            {getRoutes(emailsRoutes)}
                        </Route>

                        {/* Route to support tickets management system */}
                        <Route
                            path={APP_GROUP_LINKS.ADMIN.SUPPORT_GROUP_LINKS.LINK_TO_SUPPORT_TICKETS}
                            element={<Support />}
                        />

                        {/* Route to reports management system */}
                        <Route path={APP_GROUP_LINKS.ADMIN.REPORT_GROUP_LINKS.LINK_TO_REPORTS} element={<Report />}>
                            {getRoutes(reportsRoutes)}
                        </Route>

                        {/* Route to users management system */}
                        <Route path={APP_GROUP_LINKS.ADMIN.USERS_GROUP_LINKS.LINK_TO_USERS} element={<Users />}>
                            {getRoutes(usersRoutes)}
                        </Route>
                    </Route>
                </Route>

                {/* Route to Account verification, Reset password pages */}
                <Route path={APP_GROUP_LINKS.ACCOUNT.BASE} element={<AccountRoutes />}>
                    {getRoutes(accountRoutes)}
                </Route>

                {/* Main app */}
                <Route path={`${APP_GROUP_LINKS.CLIENT.BASE}`} element={<ClientAppRoutes />}>
                    {getRoutes(clientRoutes)}
                </Route>
            </Routes>
            <ToastContainer />
        </Suspense>
    );
};

const mapStateToProps = state => ({
    layout: state.Layout
});

export default connect(mapStateToProps)(App);
