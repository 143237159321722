export const STORAGE = {
    LOCAL: 'local',
    SESSION: 'session'
};

export const STORAGE_SPLITTER = '::';

export const KEY_USER = `${STORAGE.LOCAL}::user`;
export const KEY_LANGUAGE = `${STORAGE.LOCAL}::lng`;
export const KEY_THEME = `${STORAGE.LOCAL}::theme`;
export const KEY_LOCATION_SESSION = `${STORAGE.LOCAL}::session_place`;

export const KEY_COOKIES_ACCEPTED = `${STORAGE.LOCAL}::cookiesAccept`;
export const KEY_COOKIES_HIDDEN = `${STORAGE.LOCAL}::cookiesHidden`;
