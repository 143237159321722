import { lazy } from 'react';
import { APP_GROUP_LINKS } from '../links';

// components
const ContactUs = lazy(() => import('../../groups/clients/contact'));
const Page = lazy(() => import('../../groups/clients/pages'));

const clientRoutes = [
    {
        key: 'contact-us',
        path: APP_GROUP_LINKS.CLIENT.LINK_TO_CONTACT_US,
        element: <ContactUs />,
        authority: []
    },
    {
        key: 'private-policy',
        path: APP_GROUP_LINKS.CLIENT.LINK_TO_PRIVACY_POLICY_GROUP.LINK_TO_PRIVATE_POLICY_DETAILS,
        element: <Page />,
        authority: []
    },
    {
        key: 'page-details',
        path: APP_GROUP_LINKS.CLIENT.LINK_TO_PAGE_DETAILS,
        element: <Page />,
        authority: []
    },
    {
        key: 'home',
        path: APP_GROUP_LINKS.CLIENT.LINK_TO_HOME,
        element: <Page />,
        authority: []
    }
];

export default clientRoutes;
